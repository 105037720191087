import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from 'angularfire2/firestore';
import { observable, Observable } from 'rxjs';
import { User } from './user';
import { map } from 'rxjs/operators';
import { Subscription } from './subscription';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  userscollection: AngularFirestoreCollection<User>;
  subscribeCollection: AngularFirestoreCollection<Subscription>;
  users: Observable<User[]>;
  userDoc: AngularFirestoreDocument<User>;

  constructor(public _afs: AngularFirestore) {
    this.userscollection = this._afs.collection('contactdata', x => x.orderBy('firstname', 'asc'));
    this.subscribeCollection = this._afs.collection('subscribedata');
    this.users = this.userscollection.snapshotChanges().pipe(
      map(
        changes => {
          return changes.map(
            a => {
              const data = a.payload.doc.data() as User;
              data.id = a.payload.doc.id;
              return data;
            });
        }
      )
    );
  }


  getUsers() {
    return this.users;
  }
  addUser(user) {
    this.userscollection.add(user);
  }
  addSubscribe(subscribe) {
    this.subscribeCollection.add(subscribe);
  }
  deleteUser(user) {
    this.userDoc = this._afs.doc(`Users/${user.id}`);
    this.userDoc.delete();
  }


  getContactPageData(): Promise<any> {
    const responseData: any = [];
    return new Promise((resolve, reject) => {
      this._afs.collection('contact', x => x.where('isDeleted', '==', false)).snapshotChanges().subscribe(res => {
        res.forEach(element => {
          const response: any = {};
          response['id'] = element.payload.doc.id;
          response['data'] = element.payload.doc.data();
          responseData.push(response);
        });
        resolve(responseData);
      }, error => {
        reject(error);
      });
    });
  }

}
